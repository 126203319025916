import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#006c9f",
                secondary: "#de2e78",
                mc2i: "#1b6b9a"
            },
        },
    },
});
