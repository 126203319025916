import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import excel from 'vue-excel-export';

Vue.config.productionTip = false;



Vue.use(excel)

new Vue({
  router,
  store,
  vuetify,
  VueAxios,
  axios,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
