<template>
  <v-app>
    <Navbar v-if="userRoles.length > 0"/>
    <v-main>
      <router-view class="view"></router-view>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

export default {
  name: "App",
  components: {
    Navbar,
    Footer
  },
  computed: {
    userRoles() {
      return this.$store.getters.currentRoles;
      // return this.$store.getters.currentRole;
    },
  },
};
</script>
<style>
@import "./assets/styles/filter.css";

.v-application {
  background-color: #f4f4f4;
  font-family: Lato;
}

table th + th {
  font-weight: 1000;
}

</style>
