import axios from 'axios';
import qs from 'querystring'
import { generatePKCETokens } from '../commonFunction/pkce';

//Lien vers le domaine Amazon Cognito
let baseUrl = process.env.VUE_APP_COGNITO_BASEURL
//Id Client et clé secrète du client d'application
const requestBody = {
    client_id: process.env.VUE_APP_COGNITO_CLIENTID,
    client_secret: process.env.VUE_APP_COGNITO_CLIENTSECRET,
}

const pkce = generatePKCETokens();
const clientId = process.env.VUE_APP_COGNITO_CLIENTID;
const redirect = process.env.VUE_APP_REDIRECT
const appDomain = process.env.VUE_APP_COGNITO_APP_DOMAIN;

export const getAuthorizationCode = () => {
    localStorage.setItem('codeVerifier', pkce.codeVerifier)
    const cognitoAuthUrl = `${appDomain}/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirect}&scope=openid&code_challenge_method=S256&code_challenge=${pkce.codeChallenge}`;
    window.location.href = cognitoAuthUrl;
}

export const getAccessToken = async (authCode) => {
    let codeVerifier = localStorage.getItem('codeVerifier')
    const resp = await axios.post(
        `${appDomain}/oauth2/token`,
        {
            grant_type: 'authorization_code',
            client_id: clientId,
            code: authCode,
            code_verifier: codeVerifier,
            redirect_uri: redirect
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
    )
    return resp.data
}

export const generateNewAccessToken = async (refreshToken) => {
    const resp = await axios.post(
      `${appDomain}/oauth2/token`,
      {
        grant_type: "refresh_token",
        client_id: clientId,
        refresh_token: refreshToken,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    );
  
    return resp.data;
}

export const revokeToken = async (refreshToken) => {
    const resp = await axios.post(
      `${appDomain}/oauth2/revoke`,
      {
        token: refreshToken,
        client_id: clientId,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    );
  
    return resp.data;
  }

//Récupération du token
export function getToken() {
    let options = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };
    //Requete http POST avec les identifiants ainsi que les paramètres du header de la requête
    return axios.post(`${baseUrl}` + '?grant_type=client_credentials', qs.stringify(requestBody), options)
        .then(function (response) {
            // handle success
            return response.data;
        })
        .catch(function (error) {
            // handle error
            return error
        })
}
