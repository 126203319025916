// Obtenir le rôle d'un utilisateur
export const getRoles = (groups) => {
    
    const groupRoleMapping = {
        "ariane_rec_tamanagement": 1,	
        "ariane_rec_tabp": 2,	
        "ariane_rec_relation_ecole": 3,	
        "ariane_rec_tac_cdi_cdd": 4,	
        "ariane_rec_tac_stage_alt": 5,	
        "ariane_rec_direction_ope": 6,	
        "ariane_rec_paie": 7,	
        "ariane_rec_dg": 8,	
        "ariane_rec_direction_fct": 9,	
        "ariane_rec_tm": 10,
    };

    const finalRoles = [];
    groups.forEach(group => {
        const role = groupRoleMapping[group];
        if (role !== undefined) {
            finalRoles.push(role);
        }
    });

    return finalRoles; 
}

