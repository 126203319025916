import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/login'
  },
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },

  {
    path: '/candidats',
    redirect: () => {
      const subpath = determineSubpath();
      return `/candidats/${subpath}`;
    }
  },
  {
    path: '/embauches',
    redirect: () => {
      const subpath = determineSubpath();
      return `/embauches/${subpath}`;
    }
  },
  {
    path: '/desistements',
    redirect: () => {
      const subpath = determineSubpath();
      return `/desistements/${subpath}`;
    }
  },

  {
    path: '/candidats/ope',
    name: 'CandidatsOPE',
    component: () => import('../views/CandidatsOPE.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/candidats/fct',
    name: 'CandidatsFCT',
    component: () => import('../views/CandidatsFCT.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/embauches/ope',
    name: 'EmbauchesOPE',
    component: () => import('../views/EmbauchesOPE.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/embauches/fct',
    name: 'embauchesFCT',
    component: () => import('../views/EmbauchesFCT.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/desistements/ope',
    name: 'DesistementsOPE',
    component: () => import('../views/DesistementsOPE.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/desistements/fct',
    name: 'DesistemetsFCT',
    component: () => import('../views/DesistementsFCT.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

function determineSubpath() {
  const currentPath = router.history.current.path;
  return currentPath.includes('ope') ? 'ope' : 'fct';
}


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isConnected = store.getters.currentRoles.length > 0
  const previousPath = from.path;
  //console.log('FROM',previousPath);

  if (previousPath.includes('ope')) {
    const subpath = 'ope'
    //console.log('SubPATH =',subpath);
  } else {
    const subpath = 'fct'
    //console.log('SubPATH =',subpath);
  }
  if (to.query.code) {
    store.commit('SET_AUTH_CODE', to.query.code.toString())
    store.dispatch('googleSignInAction')
  }
  
  if (requiresAuth) {
    if (isConnected) {
      next();
    } else {
      next('/login');
    }
  } else {
    if (isConnected) {
      const subpath = determineSubpath(to.path);
      const expectedPath = `/${to.params[0]}/${subpath}`;

      if (to.path !== expectedPath) {
        const isValidRoute = router.options.routes.some(route => route.path === expectedPath);

        if (isValidRoute) {
          next(expectedPath);
        } else {
          if (to.path === '*' && expectedPath) {
            next(expectedPath)
          }else{
            next('/candidats');
          }
        }
     } else {
       next();
     }
    } else {
      next();
    }
  }
});

export default router;
