import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VNavigationDrawer,{staticClass:"d-flex align-start flex-row elevation-4",attrs:{"app":"","expand-on-hover":"","mini-variant":"","width":"184","color":"#1b6b9a","dark":""}},[_c(VList,[_c(VListItem,{staticClass:"px-2"},[_c(VImg,{attrs:{"src":require("../assets/LOGO-ARIANE-BLANC.png"),"max-width":"40","contain":""}})],1)],1),_c(VDivider),_c(VList,{staticClass:"flex-grow",attrs:{"shaped":""}},[_c(VListItemGroup,{model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VListItem,{attrs:{"to":"/candidats","value":"candidats","active-class":"bg-active","selectable":""}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-account-group")])],1),_c(VListItemTitle,[_vm._v("Candidats")])],1),_c(VListItem,{attrs:{"to":"/embauches","value":"embauches","active-class":"bg-active"}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-handshake")])],1),_c(VListItemTitle,[_vm._v("Embauchés")])],1),_c(VListItem,{attrs:{"to":"/desistements","value":"desistements","active-class":"bg-active"}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-exit-run")])],1),_c(VListItemTitle,[_vm._v("Désistements")])],1),_c(VDivider),(_vm.userRoles.includes(1) || _vm.userRoles.includes(2))?_c(VListItem,{attrs:{"to":"/admin","value":"admin","active-class":"bg-active"}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-shield-crown")])],1),_c(VListItemTitle,[_vm._v("Admin")])],1):_vm._e()],1)],1),_c(VList,{staticClass:"mt-auto",attrs:{"shaped":""}},[_c(VListItem,{on:{"click":_vm.logout}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-logout")])],1),_c(VListItemTitle,[_vm._v("Se déconnecter")])],1),_c(VListItem,{staticClass:"px-3"},[_c(VListItemAvatar,{attrs:{"size":"32"}},[_c(VImg,{attrs:{"src":_vm.getAvatarImg}})],1),_c(VListItemTitle,[_vm._v(_vm._s(_vm.getName)+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }