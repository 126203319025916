import crypto from 'crypto-js';

const generateCodeVerifier = () => {
    const codeVerifier = crypto.lib.WordArray.random(32); // 256 bits (32 bytes)
    return codeVerifier.toString(crypto.enc.Base64)
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
}

// Calculer le code de défi (code challenge) à partir du code vérifieur
const calculateCodeChallenge = (codeVerifier) => {
    const codeChallenge = crypto.SHA256(codeVerifier).toString(crypto.enc.Base64)
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');

    return codeChallenge;
}

export const  generatePKCETokens = () => {
    const codeVerifier = generateCodeVerifier();
    const codeChallenge = calculateCodeChallenge(codeVerifier);

    return {codeVerifier,codeChallenge};
}
