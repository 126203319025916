<template>
  <v-navigation-drawer
    app
    expand-on-hover
    mini-variant
    width="184"
    color="#1b6b9a"
    dark
    class="d-flex align-start flex-row elevation-4"
  >
    <v-list>
      <v-list-item class="px-2">
        <v-img 
          src="../assets/LOGO-ARIANE-BLANC.png"
          max-width="40"
          contain>
        </v-img>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>


    <v-list shaped class="flex-grow">
      <v-list-item-group v-model="drawer">

          <v-list-item to="/candidats" value="candidats" active-class="bg-active" selectable>
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Candidats</v-list-item-title>
          </v-list-item>
    

          <v-list-item to="/embauches" value="embauches" active-class="bg-active">
            <v-list-item-icon >
              <v-icon>mdi-handshake</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Embauchés</v-list-item-title>
          </v-list-item>

          <v-list-item to="/desistements" value="desistements" active-class="bg-active">
            <v-list-item-icon>
              <v-icon>mdi-exit-run</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Désistements</v-list-item-title>
          </v-list-item>

        <v-divider></v-divider>

        <v-list-item v-if="userRoles.includes(1) || userRoles.includes(2)" to="/admin" value="admin" active-class="bg-active">
          <v-list-item-icon >
            <v-icon>mdi-shield-crown</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Admin</v-list-item-title>
        </v-list-item>


      </v-list-item-group>

    </v-list>

    <v-list shaped class="mt-auto">
      <v-list-item @click="logout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Se déconnecter</v-list-item-title>
     </v-list-item>

      <v-list-item class="px-3">
        <v-list-item-avatar size="32">
          <v-img :src="getAvatarImg"></v-img>
        </v-list-item-avatar>
        <v-list-item-title>{{ getName }} </v-list-item-title>
      </v-list-item>
      
    </v-list>


  </v-navigation-drawer>
</template>

<script>
export default {
  data: () => ({
      drawer: null,
  }),
  computed: {
    userRoles() {
      return this.$store.getters.currentRoles;
    },
    getAvatarImg() {
      return this.$store.getters.avatar;
    },
    getUserName() {
      return this.$store.getters.user.given_name
    },
    getName() {
      return this.$store.getters.user.name
    },
    currentRoute() {
      return this.$route.name;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("signOutAction");
    },
  },
};
</script>

<style scoped>

.bg-active {
  background-color: white;
}

.bg-active  .v-list-item__title {
  color: #1b6b9a;
}

.bg-active .v-list-item__icon {
  color: #1b6b9a;
}

</style>