import Vue from 'vue'
import Vuex from 'vuex'
import router from "../router";
import { jwtDecode } from "jwt-decode";
import VuexPersistence from 'vuex-persist'
import { getRoles } from "../api/roles"
import { getToken, getAuthorizationCode, getAccessToken, revokeToken } from '../api/auth';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
})

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  state: {
    title: null,
    auth_code: null,
    expires_in: 0,
    cognito_groups: [],
    access_token: null,
    refresh_token: null,
    refreshingToken: false,
    loadingGoogleSignin: false,
    user: null,
    search: null,
    filters: [],
    filtersCandidats: null,
    filtersCandidatsFCT: null,
    filtersEmbauches: null,
    filtersEmbauchesFCT: null,
    filtersDesistements: null,
    filtersDesistementsFCT: null,
    toggle_candidats: "OPE",
    toggle_embauches: "",
    toggle_desistements: "",
    photoURL: null,
    dispo:'',
    menuderoulant: {},
    champs: {},
    tableau_recruteurs: [],
    tableau_candidats: [],
    tableau_embauches: [],
    tableau_desistements: [],
    tableau_candidatsFCT: [],
    tableau_embauchesFCT: [],
    tableau_desistementsFCT: [],
    tableau_ecoles: [],
    tableau_champs: [],
    currentRole: -1,
    currentRoles: [],
    dialogConnectionRefused: false,
    dialogSessionExpired: false,
    snackbar: {
      text: '',
      color: '',
    },
    
  },
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, {
        title: null,
        auth_code: null,
        expires_in: 0,
        cognito_groups: [],
        access_token: null,
        refresh_token: null,
        refreshingToken: false,
        loadingGoogleSignin: false,
        user: null,
        search: null,
        filters: [],
        filtersCandidats: null,
        filtersCandidatsFCT: null,
        filtersEmbauches: null,
        filtersEmbauchesFCT: null,
        filtersDesistements: null,
        filtersDesistementsFCT: null,
        toggle_candidats: "OPE",
        toggle_embauches: "",
        toggle_desistements: "",
        photoURL: null,
        dispo: '',
        menuderoulant: {},
        champs: {},
        tableau_recruteurs: [],
        tableau_candidats: [],
        tableau_embauches: [],
        tableau_desistements: [],
        tableau_candidatsFCT: [],
        tableau_embauchesFCT: [],
        tableau_desistementsFCT: [],
        tableau_ecoles: [],
        tableau_champs: [],
        currentRole: -1,
        currentRoles: [],
        dialogConnectionRefused: false,
        dialogSessionExpired: false,
        snackbar: {
          text: '',
          color: '',
        },
      });
    },
    SET_AUTH_CODE(state, payload) {
      state.auth_code = payload
    },
    SET_EXPIRE_IN(state, payload) {
      state.expires_in = payload
    },
    SET_REFRESH_TOKEN(state, payload) {
      state.refresh_token = payload
    },
    SET_REFRESHING_TOKEN(state, payload) {
      state.refreshingToken = payload
    },
    SET_LOADING_GOOGLE_SIGNIN(state, payload) {
      state.loadingGoogleSignin = payload
    },
    SET_USER(state, payload) {
      state.user = payload
    },
    SET_SEARCH(state, payload) {
      state.search = payload
    },
    SET_TOGGLE_CANDIDATS(state, payload) {
      state.toggle_candidats = payload
    },
    SET_TOGGLE_EMBAUCHES(state, payload) {
      state.toggle_embauches = payload
    },
    SET_TOGGLE_DESISTEMENTS(state, payload) {
      state.toggle_desistements = payload
    },
    SET_PHOTO_URL(state, payload) {
      state.photoURL = payload
    },
    SET_TITLE(state, payload) {
      state.title = payload;
    },
    SET_CHAMPS(state, payload) {
      state.champs = payload;
    },
    SET_CURRENT_MENUD(state, payload) {
      state.menuderoulant = payload;
    },
    SET_CURRENT_RECRUTEURS(state, payload) {
      state.tableau_recruteurs = payload;
    },
    SET_CURRENT_CANDIDATS(state, payload) {
      state.tableau_candidats = payload;
      state.tableau_embauches = payload;
      state.tableau_desistements = payload;
    },
    SET_CURRENT_CANDIDATSFCT(state, payload) {
      state.tableau_candidatsFCT = payload;
      state.tableau_embauchesFCT = payload;
      state.tableau_desistementsFCT = payload;
    },
    SET_CURRENT_ECOLES(state, payload) {
      state.tableau_ecoles = payload;
    },
    SET_CURRENT_MENUD(state, payload) {
      state.menuderoulant = payload;
    },
    SET_CURRENT_CHAMPS(state, payload) {
      state.tableau_champs = payload;
    },
    SET_AUTHORIZATION(state, payload) {
      //console.log('STATE',state);
      //console.log('PAYLOAD',payload);
      if (payload != null) {
        getAccessToken(this.state.auth_code).then((data)=>{
          state.loadingGoogleSignin = false
          state.expires_in = data.expires_in
          state.access_token = data.access_token
          state.refresh_token = data.refresh_token

          const decoded = jwtDecode(data.access_token);
          state.cognito_groups = decoded['cognito:groups']

          const userData = jwtDecode(data.id_token)
          state.user = userData
          state.photoURL = userData.picture

          //console.log({userData})

          if (Array.isArray(state.cognito_groups)) {
            state.loadingGoogleSignin = false
            // const roles = [];
            const roles = getRoles(state.cognito_groups);
            //console.log({roles})
            if (roles.length > 0) {
              state.currentRoles = roles;
              router.replace('candidats/ope').catch(() => { });
            } else {
              state.dialogConnectionRefused = true;
              localStorage.clear() // vider le state persist
              router.replace('/login')
            }
          }else{
            state.loadingGoogleSignin = false
            state.dialogConnectionRefused = true;
            localStorage.clear() // vider le state persist
            router.replace('/login')
          }
        }).catch((err)=>{
          //console.log({err})
          state.dialogConnectionRefused = true
          state.loadingGoogleSignin = false
        })
        
      } else {
        state.currentRoles = [];
        state.loadingGoogleSignin = false
      }
    },
    SET_DIALOG_CONNECTION_REFUSED(state, payload) {
      state.dialogConnectionRefused = payload;
    },
    SET_DIALOG_SESSION_EXPIRED(state, payload) {
      state.dialogSessionExpired = payload;
    },
    SET_ACCESS_TOKEN(state, payload) {
      state.access_token = payload
    },
    setSnackbar(state, { text, color }) {
      state.snackbar.text = text;
      state.snackbar.color = color;
    },
  },
  actions: {
    getAuthorization({commit}){
       getAuthorizationCode();
    },
    googleSignInAction({ commit }) {
      commit('SET_AUTHORIZATION', this.state.auth_code)
    },
    clearAuthData({ commit }){
      commit('SET_EXPIRE_IN', 0);
      commit('SET_ACCESS_TOKEN', null);
      commit('SET_REFRESH_TOKEN', null);
    },
    signOutAction({ commit }) {
      revokeToken(this.state.refresh_token)
        .then(() => {
          commit('RESET_STATE'); // Reset the state to its initial values
          localStorage.clear()
          router.replace('login').catch(() => {});
        })
        .catch((err) => {
          //console.log({ err });
        });
    },
    showSnackbar({ commit }, { text, color }) {
      commit('setSnackbar', { text, color });
    },
  },
  modules: {
  },
  getters: {
    expires_in(state) {
      return state.expires_in;
    },
    title(state) {
      return state.title;
    },
    avatar(state) {
      return state.photoURL;
    },
    user(state) {
      return state.user;
    },
    search(state) { 
      return state.search;
    },
    toggle(state) {
      return state.toggle;
    },
    toggle_candidats(state) {
      return state.toggle_candidats;
    },
    recruteurs(state) {
      return state.tableau_recruteurs;
    },
    candidats(state) {
      return state.tableau_candidats;
    },
    embauches(state) {
      return state.tableau_embauches;
    },
    desistements(state) {
      return state.tableau_desistements;
    },
    candidatsFCT(state) {
      return state.tableau_candidatsFCT;
    },
    embauchesFCT(state) {
      return state.tableau_embauchesFCT;
    },
    desistementsFCT(state) {
      return state.tableau_desistementsFCT;
    },
    ecoles(state) {
      return state.tableau_ecoles;
    },
    menuderoulant(state) {
      return state.menuderoulant;
    },
    champs(state) {
      return state.champs;
    },
    currentRole(state) {
      return state.currentRole;
    },
    currentRoles(state) {
      return state.currentRoles;
    },
    dialogConnectionRefused(state) {
      return state.dialogConnectionRefused;
    },
    dialogSessionExpired(state) {
      return state.dialogSessionExpired;
    },
    access_token(state) {
      return state.access_token
    },
    refresh_token(state) {
      return state.refresh_token
    },
    refreshingToken (state) {
      return state.refreshingToken
    },
    loadingGoogleSignin (state) {
      return state.loadingGoogleSignin
    }
  }
})
